<template>
	<el-form class="page" :model="cene" :rules="rules" ref="form" :label-position="'top'">
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-tooltip class="item" effect="dark" :content="$t('global.nazad')" placement="top">
						<el-button @click="$utils('goBack')" type="info" icon="fas fa-hand-point-left" circle></el-button>
					</el-tooltip>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item size="large">
					<forma-cena v-model="cene"></forma-cena>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-button :disabled="onSubmitLoading || $utils('loIsEqual', ceneOriginal, cene)" v-loading="onSubmitLoading" type="success" @click="onSubmit">{{ $t('global.sacuvaj') }}</el-button>
					<el-button type="info" @click="$utils('goBack')">{{ $t('global.odustani') }}</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
export default {
	name: 'cene-edit',
	data() {
		return {
			dialogVisible: false,
			onSubmitLoading: false,
			fullscreenLoading: true,
			id: '',
			formCreate: '',
			ceneOriginal: {},
			cene: {
				naziv_cenovnika: '',
				id_linije: null,
				naziv_linije: '',
				datum_od: null,
				datum_do: null,
				dani_polazak: [],
				dani_povratak: [],
				vrsta_cene: 'fiksna', // enum
				cena_fiksna: null,
				cena_min: null,
				cena_max: null,
				min_cena_povratne: 0,
				koeficijent_povratne: null,
				id_model_obracuna: null,
				stopa_rasta: null,
				mnozilac: null,
				isplativost: null,
				id_valuta: null,
				broj_raspolozivih: null,
				last_minute: 'NE', // enum
				first_minute: null,
				last_minute_min_isplativosti: null,
				last_minute_max_isplativosti: null,
				last_minute_fiksni_popust: null,
				last_minute_procenat_popust: null,
				last_minute_fiksna_cena: null,
				koeficijenti_starosne_grupe: [],
				cene: [{
					cena: 0,
					raspolozivo: 1,
					iskorisceno: 0
				}]
			},
			proveraPromena: [
				'naziv_cenovnika',
				'id_linije',
				'isplativost',
				'id_valuta',
				'broj_raspolozivih',
				'last_minute',
				'last_minute_max_isplativosti',
				'last_minute_min_isplativosti',
				'last_minute_fiksni_popust',
				'last_minute_procenat_popust',
				'last_minute_fiksna_cena',
				'koeficijenti_starosne_grupe'
			],
			rules: {
				datum_od: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				datum_do: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				naziv_cenovnika: [{ required: true, max: 250, trigger: 'blur', message: this.$t('message.obavezno') }],
				id_linije: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				dani_polazak: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				dani_povratak: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				id_valuta: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				id_model_obracuna: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				broj_raspolozivih: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				stopa_rasta: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				cena_max: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }]
			}
		};
	},
	mounted() {
		this.id = this.$route.params.id;
		this.formCreate = this.id === 'create';

		this.$store.dispatch('setHeader', {
			title: this.$t('cene.cenovnik') + ' ' + this.cene.naziv_cenovnika
		});

		this.$utils('stopLoadingAfter', [this.getCene(), this.getStarosneGrupe()]);
	},
	methods: {
		getCene() {
			if (!this.formCreate) {
				this.$get('cenovnik', { id: this.id }).then(data => {
					this.cene = data;
					this.ceneOriginal = _.cloneDeep(this.cene);
				});
			}
			else
				this.ceneOriginal = _.cloneDeep(this.cene);
		},
		onSubmit() {
			// let proveraPromena = this.proveraPromena;
			// let cene = _.cloneDeep(this.cene);
			// _.forEach(this.ceneOriginal, (orgValue, orgKey) => {
			// 	let includes = _.includes(proveraPromena, orgKey);
			//
			// 	if (!includes)
			// 		console.log(orgKey, '|', _.isEqual(orgValue, cene[orgKey]));
			// });
			if (this.cene.last_minute === 'DA' && this.cene.last_minute_max_isplativosti <= this.cene.last_minute_min_isplativosti) {
				this.onSubmitLoading = false;
				this.$alert(this.$t('cene.lastMinuteIsplativostNijeValidna') + ' ' + this.cene.last_minute_max_isplativosti, this.$t('confirm.upozorenje'), {
					confirmButtonText: this.$t('global.uredu')
				});
			}
			else {
				return this.$refs.form.validate().then(() => {
					this.onSubmitLoading = true;
					let data = JSON.stringify(this.cene);

					if (this.formCreate)
						this.$save('cenovnik', data);
					else
						this.$update('cenovnik', { id: this.id }, data);
				}).catch((err) => {
					this.onSubmitLoading = false;
					return err;
				});
			}
		},
		getStarosneGrupe() {
			return this.$get('starosneGrupe', { size: 2000 }).then(data => {
				let starosneGrupe = [];
				data.content.forEach((value) => {
					starosneGrupe.push({ 'id_starosne_grupe': value.id, 'naziv': value.naziv, 'koeficijent': 1, 'max_cena_povratne': 0 });
				});
				this.cene.koeficijenti_starosne_grupe = _.merge(starosneGrupe, this.cene.koeficijenti_starosne_grupe);
				this.cene.koeficijenti_starosne_grupe.map(grupa => {
					if (grupa.naziv.toLowerCase().search('gratis') > -1) {
						grupa.koeficijent = 0;
						grupa.max_cena_povratne = 0;
					}
				});
			});
		}
	},
	components: {
		formaCena: require('./forma').default
	}
};
</script>
